import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';

import config from '../../config.json';
import {
  BackgroundTexture,
  BalloonIcon,
  HeadingWithIcon,
  Layout,
  SEO,
  SideBySide,
} from '../components';
import { ContactFormWithMap } from '../components/contact-form-with-map';
import { GiftIcon } from '../components/vectors/gift';
import { useGraphQL } from '../hooks/use-graphql';

function FunctionsPage(): React.ReactElement {
  const { functionAd } = useGraphQL();
  return (
    <Layout>
      <SEO title="Functions" />
      <HavingAFunction />
      <FunctionPackages />
      <PromoImage image={functionAd.childImageSharp.gatsbyImageData} />
      <ContactFormWithMap background="blue" />
    </Layout>
  );
}

function HavingAFunction(): React.ReactElement {
  const { functionHavingAFunction } = useGraphQL();
  return (
    <div className="relative py-12">
      <BackgroundTexture insetBottom="full" overflowBottom />
      <div className="relative">
        <SideBySide
          sideComponent={
            <GatsbyImage
              image={functionHavingAFunction.childImageSharp.gatsbyImageData}
              objectFit="contain"
              className="my-auto"
              alt=""
            />
          }
        >
          <div className="flex flex-col justify-center w-full p-4 pt-8 mx-auto max-w-prose">
            <HeadingWithIcon>
              <HeadingWithIcon.Heading>
                Having a <br />
                Function
              </HeadingWithIcon.Heading>
              <HeadingWithIcon.Icon hidden>
                <BalloonIcon />
              </HeadingWithIcon.Icon>
            </HeadingWithIcon>
            <div className="mt-5 prose">
              <p>
                At Port City Bowling Club, our service is the difference. As
                your venue partner we pride ourselves on offering a friendly and
                professional approach to all your function needs.
              </p>
              <p>
                Our Function Coordinator will work closely with you to ensure
                that your special day or event is exactly how you imagined. Our
                team combines style, creativity, dedication and quality service
                to ensure that your event is everything you desire.
              </p>
              <p>
                We know not everyone is the same and they are committed. To
                ensure that every aspect of your event will be coordinated with
                precision and professionalism.
              </p>
              <p>
                If you would like more information, please do not hesitate to
                contact Rose today on{' '}
                <a href={`tel:${config.phone}`} className="font-semibold">
                  {config.phone}
                </a>{' '}
                or email{' '}
                <a href={`mailto:${config.functionsEmail}`}>
                  {config.functionsEmail}
                </a>
              </p>
            </div>
          </div>
        </SideBySide>
      </div>
    </div>
  );
}

function FunctionPackages(): React.ReactElement {
  const { functionPackages } = useGraphQL();

  return (
    <div className="py-12">
      <SideBySide
        isReversed={false}
        sideComponent={
          <GatsbyImage
            image={functionPackages.childImageSharp.gatsbyImageData}
            className="flex-1 h-full"
            alt=""
          />
        }
      >
        <div className="flex h-full py-8 bg-blue-light text-blue-dark">
          <div className="flex flex-col justify-center w-full p-4 pt-8 mx-auto max-w-prose">
            <HeadingWithIcon>
              <HeadingWithIcon.Heading>
                Function <br />
                Packages
              </HeadingWithIcon.Heading>
              <HeadingWithIcon.Icon hidden>
                <GiftIcon />
              </HeadingWithIcon.Icon>
            </HeadingWithIcon>
            <div className="mt-5 prose">
              <p>
                Port City offers a wide range of options specific to each
                function or event.
              </p>
              <p>Our services are limited only by your imagination!</p>
            </div>
            <p className="mt-5">
              <a
                href={`tel:${config.phone}`}
                className="inline-flex items-center justify-center w-full px-6 button xs:w-auto "
              >
                <span className="hidden xs:block">
                  Call to book - {config.phone}
                </span>
                <span className="block text-center xs:hidden">
                  Call to book <br />
                  {config.phone}
                </span>
              </a>
            </p>
          </div>
        </div>
      </SideBySide>
    </div>
  );
}

function PromoImage({ image }) {
  return (
    <div className="relative py-12">
      <BackgroundTexture insetBottom="full" overflowBottom />
      <div className="relative">
        <div className="relative flex w-full px-4 mx-auto max-w-screen-2xl sm:px-6 lg:px-16">
          <GatsbyImage
            image={image}
            objectFit="contain"
            className="w-full my-auto"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export { FunctionsPage as default };
