import * as React from 'react';

function GiftIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg fill="currentColor" viewBox="0 0 512 512" {...props}>
      <path d="M492 106.004H376.691c5.704-9.558 8.842-19.105 9.326-28.527.709-13.811-4.341-26.537-14.607-36.802-11.013-11.014-24.712-15.987-39.613-14.384-15.566 1.674-31.351 10.416-46.917 25.981-11.191 11.191-21.293 24.933-28.88 39.003-7.587-14.069-17.689-27.812-28.88-39.003-15.566-15.566-31.352-24.308-46.917-25.982-14.9-1.6-28.6 3.371-39.612 14.384-10.267 10.266-15.317 22.992-14.608 36.803.484 9.423 3.623 18.969 9.326 28.527H20c-11.028 0-20 8.972-20 20v60c0 11.028 8.972 20 20 20v260c0 11.028 8.972 20 20 20h431.999c11.028 0 20-8.972 20-20v-260c11.028 0 20-8.972 20-20v-60c.001-11.029-8.972-20-19.999-20zM299.023 66.413c12.083-12.083 24.156-19.082 34.913-20.238 8.802-.944 16.651 1.96 23.332 8.641 13.317 13.317 11.474 31.426-5.112 51.187H319.41l13.973-13.755c3.936-3.874 3.986-10.206.111-14.142-3.874-3.936-10.206-3.986-14.142-.111L290.9 106.004h-19.952c6.855-14.092 16.974-28.49 28.075-39.591zm-93.025 119.591v-60h100.004v60H205.998zM154.732 54.816c6.68-6.68 14.527-9.585 23.332-8.641 10.757 1.157 22.83 8.155 34.913 20.239 11.101 11.1 21.22 25.498 28.075 39.59H221.1l-28.453-28.008c-3.936-3.875-10.268-3.826-14.142.111-3.875 3.936-3.825 10.268.111 14.142l13.974 13.755h-32.747c-16.585-19.762-18.428-37.871-5.111-51.188zM19.998 126.004h166v60H20l-.002-60zm20.002 80h165.998V291c0 5.523 4.477 10 10 10s10-4.477 10-10v-84.997H286v260h-60.002V381c0-5.523-4.477-10-10-10s-10 4.477-10 10v85.004H40v-260zm431.999 260H306.001v-260h165.998v260zm20.001-280H326.003v-60H492v60z" />
      <path d="M215.999 346.004c5.523 0 10-4.477 10-10v-.007c0-5.523-4.477-9.996-10-9.996s-10 4.48-10 10.003 4.477 10 10 10z" />
    </svg>
  );
}

export { GiftIcon };
